<template>
  <div class="">
    <section class="lightColor bg-image ">
      <b-row class="p-lg-5 ml-5 mr-5  pt-2">
        <b-col cols="12" sm="6" class="pl-1">
          <div class="section1 pl-1 ml-0 mb-14 mb-sm-0 mb-lg-12 pb-0">
            <span class="section-title pb-5 font-size-18">
              List of Companies Trusted, Reviewed & Recommended By Verified
              Clients</span
            >
            <h2 class="section-subtitle pt-5 text-capitalize">
              Partnering With the Most Experienced and Trusted Tech Companies
              For Your Next Projects
            </h2>
            <div class="mt-4 pt-4">
              <router-link to="/findcompany" class="">
                <b-button
                  class="
                    button-bg-dark
                    mr-lg-4
                    mb-5
                    border-radius-8
                    font-size-20
                  "
                  >Find Companies</b-button
                ></router-link
              >
              <router-link to="/getlisted"
                ><b-button
                  class="outline-dark border-radius-8 mb-5 ml-2 font-size-20"
                  >Get Listed</b-button
                ></router-link
              >
            </div>
          </div>
        </b-col>
        <b-col cols="12" sm="6" class="mt-sm-15  mt-lg-0 pt-lg-0 pt-sm-15">
          <b-img
            src="image/BannerImage-2.webp"
            class=" d-none d-sm-block fadeInDown w-40"
            fluid
            alt="Responsive image"
          ></b-img>
        </b-col>
      </b-row>
    </section>
    <section>
      <b-row class="pt-15 ml-lg-15 ml-5 mr-5 mr-lg-15 pb-10">
        <div class="text-center">
          <span class="section-title pb-5 font-size-18"
            >Why FeedView to List Your Company?</span
          >
          <h2 class="section-subtitle section2-title">Free Website Listing</h2>
        </div>
        <div class="pt-5 pl-5 mt-5">
          <b-row class="">
            <b-col cols="12" sm="5" class="ralative">
              <div class="imgdiv-home" flude="true">
                <b-img
                  src="image/img-bg.png"
                  alt="Image"
                  class=""
                  width="400"
                  fluid
                >
                </b-img>
              </div>
            </b-col>

            <b-col cols="12" sm="7" class="pt-15">
              <div class="text-center">
                <b-row class="gap-2 flex-sm-nowrap flex-md-nowrap gap-lg-0 pb-2">
                  <b-col
                    class="real-review text-left pt-sm-3 pt-lg-8 pt-md-6 pl-sm-8 pl-12 pl-lg-12 pl-md-10 pl-xl-12 font-size-18 "
                    cols="12"
                    sm="6"
                  >
                    Real Reviews from Company Representatives
                  </b-col>
                  <b-col
                    class="verified text-left pt-sm-5 pt-lg-8 pt-md-6 pt-md-4 pl-sm-8 pl-12 pl-lg-12 pl-md-10  pl-xl-12 font-size-18"
                    cols="12"
                    sm="6"
                  >
                    Manually Verified <br />
                    Companies
                  </b-col>
                </b-row>
                <b-row class="pb-5 pb-lg-5 flex-sm-nowrap flex-md-nowrap gap-2 gap-lg-0">
                  <b-col
                    class="process text-left pt-sm-5 pt-lg-8 pt-md-6 pl-12 pl-lg-12 pl-xl-12 pl-md-10 pl-sm-8 font-size-18"
                    cols="12"
                    sm="6"
                  >
                    Simple and Agile <br />
                    Process
                  </b-col>
                  <b-col
                    cols="12"
                    class="separate text-left py-sm-2 pt-lg-8 pt-md-6 pl-12 pl-lg-12 pl-xl-12 pl-md-10 pl-sm-8 font-size-18"
                    sm="6"
                    >Separate Review for Each Categories of Services
                  </b-col>
                </b-row>
                <router-link to="/findcompany"
                  ><b-button
                    class="button-bg-dark mt-5 border-radius-8 font-size-20"
                    >Your Competitors Are Here</b-button
                  ></router-link
                >
              </div>
            </b-col>
          </b-row>
        </div>
      </b-row>
    </section>
    <section class="p-lg-5 pl-lg-5 pr-0 ml-lg-15 ml-7 mr-5 mr-lg-15 mt-15">
      <b-row>
        <div class="text-center">
          <span class="section-title pb-5 font-size-18">
            How To Get Listed?</span
          >
          <h2 class="section-subtitle section3-title text-capitalize">
            How to list your Company?
          </h2>
        </div>
        <b-row class="mt-3 pl-0 pr-0">
          <b-col cols="12" class="pr-0" sm="4">
            <b-card
              class="lightColor border-0 border-radius-15 hovereffect h-100"
            >
              <router-link to="/login" class="text-decoration-none">
                <div class="p-lg-3 text-center section3-card text-hover pb-0">
                  <b-img
                    src="image/Sign-Up.svg"
                    alt="Image 1"
                    height="300"
                    class="w-100"
                  ></b-img>
                  <p class="mt-14 font-size-20 text-hover">
                    Sign Up <br />with LinkedIn
                  </p>
                </div></router-link
              >
            </b-card>
          </b-col>
          <b-col cols="12" class="pr-0" sm="4">
            <b-card
              class="lightColor border-0 border-radius-15 hovereffect h-100"
            >
              <router-link to="/getlisted" class="text-decoration-none">
                <div class="p-3 text-center section3-card text-hover pb-0">
                  <b-img
                    class="w-100"
                    src="image/Company-Details.svg"
                    alt="Image 2"
                    height="300"
                  ></b-img>
                  <p class="mt-11 font-size-20 text-hover">
                    Fill your <br />
                    Company Details
                  </p>
                </div>
              </router-link>
            </b-card>
          </b-col>
          <b-col cols="12" class="pr-0" sm="4">
            <b-card
              class="lightColor border-radius-15 border-0 hovereffect h-100"
            >
              <router-link to="/login" class="text-decoration-none">
                <div class="p-3 text-center section3-card text-hover pb-0">
                  <b-img
                    class="w-100"
                    src="image/Get-Review.svg"
                    alt="Image 3"
                    height="300"
                  ></b-img>
                  <p class="mt-14 font-size-20 hovereffect text-hover">
                    Get Reviews <br />from your Clients
                  </p>
                </div>
              </router-link>
            </b-card>
          </b-col>
        </b-row>
      </b-row>
    </section>
    <section class="lightColor find-agency">
      <div
        class="text-center p-lg-5 p-4 m-5 mr-lg-15 ml-lg-15 m-lg-5 mb-0 pb-0"
      >
        <h2 class="section-subtitle pt-2 section3-title text-capitalize">Find an Agency</h2>
      </div>
      <div class="pb-10 pl-lg-5 ml-7 mr-1 pr-6 m-lg-5 mr-lg-15 ml-lg-15 mt-3">
        <b-row>
          <b-col cols="12" sm="6" md="6" lg="3" >
            <b-card
              class="
                h-100
                
                text-center
                border-radius-15
                lightColor
                border-2-color
                pt-4
                pb-4
                hovereffect
              "
            >
              <router-link
                to="/findcompany/web-and-software-development"
                class="font-size-18"
              >
                <b-card-header class="border-0 bg-none">
                  <b-img
                    src="image/Ecommerce-Development.svg"
                    alt="Circle image"
                    height="80"
                    
                  ></b-img>
                </b-card-header>
                <b-card-text class="mt-5 mb-sm-2 mb-md-5 mb-lg-5 agency-subtile">
                  <p class="letter-spacing-0 font-size-18 primary-color">
                    <strong>Ecommerce Development</strong>
                  </p>
                  <p class="font-size-14 primary-color font-weight-500">
                    Find World’s Top Ecommerce Development Companies
                  </p></b-card-text
                ><div class="">SEE ALL <b-icon icon="chevron-right" scale="1"></b-icon
              ></div>
              </router-link>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3">
            <b-card
              class="
                h-100
                text-center
                border-radius-15
                lightColor
                border-2-color
                pt-4
                pb-4
                hovereffect
              "
            >
              <router-link
                to="/findcompany/web-and-software-development"
                class="font-size-18"
              >
                <b-card-header class="border-0 bg-none">
                  <b-img
                    src="image/Web-and-Software-Development.svg"
                  
                    alt="Circle image"
              
                     height="80"
                  ></b-img>
                </b-card-header>
                <b-card-text class="mt-5 mb-sm-2 mb-md-5 mb-lg-5 agency-subtile">
                  <p class="letter-spacing-0 font-size-18 primary-color">
                    <strong>Web and Software Development </strong>
                  </p>
                  <p class="font-size-14 primary-color font-weight-500">
                    Find World’s Best Website Development Companies
                  </p></b-card-text
                ><div>
                SEE ALL <b-icon icon="chevron-right" scale="1"></b-icon
              ></div></router-link>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3">
            <b-card
              class="
                h-100
                text-center
                border-radius-15
                lightColor
                border-2-color
                pt-4
                pb-4
                hovereffect
              "
              ><router-link
                to="/findcompany/mobile-app-development"
                class="font-size-18"
              >
                <b-card-header class="border-0 bg-none">
                  <b-img
                    src="image/Mobile-App-Development.svg"
                    
                    alt="Circle image"
                
                     height="80"
                   
                  ></b-img>
                </b-card-header>
                <b-card-text class="mt-5 mb-sm-2 mb-md-5 mb-lg-5 agency-subtile">
                  <p class="letter-spacing-0 font-size-18 primary-color">
                    <strong>Mobile App Development</strong>
                  </p>
                  <p class="font-size-14 primary-color font-weight-500">
                    Find World’s Top Mobile App Development Companies
                  </p></b-card-text
                ><div>
                SEE ALL <b-icon icon="chevron-right" scale="1"></b-icon
              ></div></router-link>
            </b-card>
          </b-col>
          <b-col cols="12" sm="6" md="6" lg="3">
            <b-card
              class="
                h-100
                text-center
                border-radius-15
                lightColor
                border-2-color
                pt-4
                pb-4
                hovereffect
              "
            >
              <router-link
                to="/findcompany/digital-marketing"
                class="font-size-18"
              >
                <b-card-header class="border-0  bg-none">
                  <b-img
                    src="image/Digital-Marketing.svg"
                  
                    alt="Circle image"
                  
                     height="80"
                  ></b-img>
                </b-card-header>
                <b-card-text class="mt-5 mb-sm-2 mb-md-5 mb-lg-5 agency-subtile">
                  <p class="letter-spacing-0 font-size-18 primary-color">
                    <strong>Digital Marketing </strong>
                  </p>
                  <p class="font-size-14 primary-color font-weight-500">
                    Find World’s Best Digital Marketing Agencies for Your
                    Business
                  </p></b-card-text
                ><div>SEE ALL <b-icon icon="chevron-right" scale="1"></b-icon
              ></div></router-link>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </section>
    <section>
      <div
        class="
          text-center
          p-5
          pt-10 pt-lg-5
          mr-lg-15
          ml-lg-15
          m-5
          mb-1
          mt-0
          pb-0
        "
      >
        <span class="section-title font-size-20">Companies</span>
        <h2 class="section-subtitle section3-title">Top Listed Companies</h2>
      </div>
      <div
        class="pb-10 pl-5 pr-6 pt-0 mt-3 ml-lg-15 ml-5 mr-5 mr-lg-15 top-listed"
      >
        <b-row>
          <b-col
            cols="12"
            sm="4"
            md="4"
            lg="2"
            v-for="(items, index) in company_detail_reverse.slice(0, 6)"
            :key="index"
          >
            <b-card
              class="text-center border-radius-25 h-100"
             
              @click="goToCompanyDetail(items.slug)"
            >
              <b-row>
                <div class="company-logo mt-0" v-if="items.company_logo">
                  <b-img
                    variant="primary"
                    :src="items.company_logo"
                    rounded="circle"
                    class="text-center img-border-top-radius w-100"
                    height="100"
                    
                  ></b-img>
                </div>
                <div class="company-logo mt-0" v-if="!items.company_logo">
                  <b-img
                    variant="primary"
                    src="/public/noImage.png"
                    rounded="circle"
                    class="img-border-top-radius"
                    height="50"
                    width="50"
                  ></b-img>
                </div>
              </b-row>
              <b-row>
                <b-col>
                  <div class="text-bold">
                    <strong>{{ items.company_name }}</strong>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <div class="text-center mt-5">
          <router-link to="/listcompany"
            ><b-button class="button-bg-dark border-radius-8 font-size-20"
              >See More</b-button
            ></router-link
          >
        </div>
      </div>
    </section>
    <section class="lightGray">
      <div class="text-center p-5 m-5 mt-1 mb-0 pb-0">
        <span class="section-title font-size-20"> Testimonials</span>
        <h2 class="section-subtitle section3-title">From Our Users</h2>
      </div>
      <b-row class="pt-0 mt-0 ml-5 mr-5">
        <b-carousel v-model="slide" :interval="4000" indicators>
          <!-- Slides with img slot -->
          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
          <b-carousel-slide
            v-for="(items, index) in testimonial_detail"
            :key="index"
          >
            <template slot="img">
              <b-card class="h-100 m-lg-4 testi-quote-bg">
                <b-row>
                  <b-col cols="12" sm="6" class="text-center">
                    <b-img :src="items.image" height="400"></b-img>
                  </b-col>
                  <b-col cols="12" sm="6" class="pt-10">
                    <h1 class="text-center">{{ items.name }}</h1>
                    <b-img
                      src="image/left-quote.png"
                      width="30"
                      height="30"
                      alt="Fluid image"
                    ></b-img>
                    <b-card-text
                      class="pl-10 pr-1 mb-0 font-size-20"
                      v-html="items.description"
                    ></b-card-text>
                    <b-img
                      src="image/right-quote.png"
                      width="30"
                      height="30"
                      class="testi-quote"
                      alt="Fluid image"
                    ></b-img>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-carousel-slide>
        </b-carousel>
      </b-row>
    </section>
    <section>
      <div class="text-center p-5 mt-4 mb-5 mr-lg-15 ml-lg-15 mb-0 pb-0">
        <span class="section-title font-size-20"> Blogs</span>
        <h2 class="section-subtitle section3-title">
          Grow Your Company With Our Resources
        </h2>
      </div>
      <b-row class="pb-10 pr-1 mt-0 ml-lg-15 ml-5 mr-5 mr-lg-15">
        <b-col
          cols="12"
          sm="6"
          md="6"
          lg="3"
          class="pr-2 p-sm-1"
          v-for="(item, index) in reverse_blog.slice(0, 4)"
          :key="index"
        >
          <div
            class="image-with-text"
            @click="goToBlogDetail(item['id'])"
            style="cursor: pointer"
          >
            <b-img
              :src="item['banner_image']"
              alt="Circle image"
              class="w-100 blog-img"
              height="350"
            ></b-img>
            <div class="pt-3 p-3" v-if="item['description']">
              <p
                class="letter-spacing-0 text-justify" v-if=" item['description'].length>100"
                v-html="
                  item['description']
                    .replace(/(<([^>]+)>)/gi, '')
                    .substring(0, 100)
                "
              ></p>
              <p
              v-else
                class="letter-spacing-0 text-justify"
                v-html="
                  item['description']
                    .replace(/(<([^>]+)>)/gi, '')
                   
                "
              ></p>
          
            </div>
          </div>
        </b-col>
      </b-row>
    </section>
    <section>
      <div class="text-center p-lg-5 p-2 mt-0 mr-lg-15 ml-lg-15 mb-0 pb-5">
        <span class="section-title"> Explore Our Community</span>
        <h3 class="section-subtitle pt-2 section4-title">
          Post Your Question Or Poll And Get Answers From Thousands Of Experts
          Worldwide
        </h3>
      </div>
      <div class="mr-lg-15 pl-lg-5 pr-lg-5 ml-lg-15">
        <b-row
          v-for="(items, index) in answer_question_data"
          :key="index"
          @click="makevisible(index)"
          class="lightColor w-100 m-0 mb-5"
        >
          <b-col cols="12" lg="12" class="pb-0 pb-lg-2 pb-sm-2" sm="12">
            <b-avatar icon="question-diamond-fill" size="md"></b-avatar>

            <span class="fs-sm-6 fw-bolder">{{ items.question }}</span>
            <span class="p-lg-2 p-1 font-13 pt-2 float-end">{{
              items.created_at | formatDate
            }}</span>
          </b-col>

          <div v-if="index == vid" class="">
            <b-collapse
              id="collapse-4"
              v-model="visible"
              class="mt-2 mb-2 bg-transparent"
              v-for="(aitem, aindex) in items.answer_question"
              :key="aindex"
            >
              <b-card class="">{{ aitem.answer }}</b-card>
            </b-collapse>
          </div>
        </b-row>
      </div>
      <div class="text-center mt-5 mb-5">
      <router-link to="/askquestion-detail"
            ><b-button class="button-bg-dark border-radius-8 font-size-20"
              >See More</b-button
            ></router-link
          >
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Home",

  data: function () {
    return {
      vid: null,
      visible: false,
      rating: 3,
      category: null,
      slide: null,
      items: ["1", "2"],
      selected: null,
      blog: [],
      company: [],
      reverse_blog: [],
      company_detail: [],
      company_detail_reverse: [],
      testimonial_detail: [],
      answer_question_data: [],
      Categoryname: [],
    };
  },
  
  mounted() {
    this.getcompany();
  },
  methods: {
    ...mapActions([
      "getBlog",
      "getCompanyDetail",
      "fetchTestimonialData",
      "getQuestionAnswerList",
      "getCompanyCategory",
    ]),

    makevisible(id) {
      this.vid = id;
      this.visible = !this.visible;
    },
    goToBlogDetail(id) {
      this.$router.push({ name: "blogdetail", params: { id: id } });
    },
    goToCompanyDetail: function (slug) {
      this.$router.push({ name: "profile", params: { id: slug } });
    },

    getcompany() {
      let cate = "";
      this.getCompanyDetail(cate).then((response) => {
        this.company_detail = response.data.data;
        this.company_detail_reverse = [].concat(this.company_detail).reverse();
      });

      this.fetchTestimonialData().then((response) => {
        this.testimonial_detail = response.data;
      });

      this.getBlog().then((response) => {
        response.data.data.forEach((value) => {
          this.blog.push(value);
        });
        this.reverse_blog = [].concat(this.blog).reverse();
      });
      this.getQuestionAnswerList({page:"home"}).then((response) => {
        this.answer_question_data = response.data;
       
      });
    },
  },
};
</script>


